//jQuery.noConflict();
import mediumZoom from 'medium-zoom'
import pinchZoom from "./lib/pinchZoom.js"

const $ = require("jquery");
(function ($) {
	var AppGeneral = {
		init: function()
		{
			var self = this;

			self.Homepage.init();
			self.Heights.init();
			self.Menu.init();
			self.Tools.init();
			self.Content.init();
			self.Zoom.init();
		},

		Zoom: {
			init: function() {
				if(window.innerWidth >= 768) {
					mediumZoom('[data-zoomable]');
				}
				else {
					document.querySelectorAll('[data-zoomable]').forEach(function(el) {
						pinchZoom(el);
					});
				}
			}
		},

		Homepage: {
			selectors: {},

			init: function()
			{
			  var self = this;

			},
		},

		Heights: {
			applyTo: [
		      ['.homepage', '.bloc-hp:not(.noheight)'],
		      ['.section-ope', '.teaser-ope .animation-info'],
		      ['.section-actus', '.teaser-content'],
		      ['.section-actus', '.teaser-img'],
		      ['.section-essentiels', '.essentiel-img'],
		      ['.section-essentiels', '.essentiel-content'],
		      ['.section-quotas', '.teaser-ope-content .animation-info'],
	    	],

	    	init: function()
		    {
				var self = this;

				setTimeout(function() {
					self.applyHeight(self.applyTo);
				},100);

				$(window).resize(function(){
					setTimeout(function() {
					  self.applyHeight(self.applyTo);
					}, 100);
				});
		    },

		    applyHeight: function(element)
		    {
			    var self = this;

		        $.each(element, function(i, apply){
		          self.heighten(apply[0], apply[1]);
		        });
		    },

		    /**
		     * Fonction de mise à niveau d'élements relativement a d'autres de même selecteur, a utiliser ou nécessaire
		     * @param container string : le container dans lequel rechercher les éléments a ajuster
		     * @param element string : le selecteur sur lequel appliquer la hauteur
		     */
		    heighten: function(container, element)
		    {
				var $container = $(container);

				if($container.length) {
					var height = 0;
					$container.each(function(){
					  $(this).find(element).each(function(){
					    var $this = $(this);
					    $this.css('height', '');
					    if($this.height() > height) {
					      height = $this.height();
					    }
					  });

					  if(height) {
					    $(this).find(element).each(function(){
					      $(this).height(height);
					    });
					  }
					});
				}
		    }
		},

		Menu: {
			selectors: {},

		    init: function()
		    {
		      var self = this;

		      //self.viewMode();
		      $(window).resize(function() {
		    	//self.viewMode();
		      });

		      $('.sidebar-menu>li').hover(function() {
		      	if($('body').hasClass('sidebar-collapse')) {
		      		$(this).siblings('li').addClass('no-color');
		      	}
		      }, function() {
		      	$(this).siblings('li').removeClass('no-color');
		      });
		    },

		    viewMode: function()
		    {
		    	var self = this;

		    	if($(window).width() >= 768 && $(window).width() < 1200) {
			    	if(!$('body').hasClass('sidebar-collapse')) {
			    		$('body').addClass('sidebar-collapse');
			    	}
			    }
			    else if($(window).width() >= 1200) {
			    	if($('body').hasClass('sidebar-collapse')) {
			    		$('body').removeClass('sidebar-collapse');
			    	}
			    }
		    },
		},

		Slider: {
			selectors: {
				slider: '',
			},

			init: function()
			{
				var self = this;
			},

			slider: function()
			{
				var self = this;

				if($(self.selectors.slider).length < 1) { return; }

				$(self.selectors.slider).slick({
					slidesToShow: 4,
					slidesToScroll: 1,
					dots: true,
					arrows:false,
					infinite:false,
					responsive: [
					    {
					      breakpoint: 1200,
					      settings: {
					        slidesToShow: 3,
					      }
					    },
					],
				});
			},
		},

		Tools: {
			selectors: {},

			init: function()
			{
				var self = this;

				self.backgroundImg();
				self.datepicker();
				self.counter();
			  	self.fav();
				self.Actions.init();
				if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
					self.Dropdown.init();
				}
				self.DropdownHeader();
				self.Select.selectIsOpen();
				self.DropdownBis.init();
				self.SelectSearch.init();
				self.CheckboxList.init();
				self.Quantity.init();
				self.Edition.init();
			},

			backgroundImg: function() 
			{
				var self = this;

				$('[data-bgimg]').each(function() {
					$(this).css('background-image', 'url("' + $(this).attr('data-bgimg') + '")').removeAttr('data-bgimg');
				});
			},

			datepicker: function()
			{
				var self = this;

				$('.date-picker').datepicker({
					language: 'fr',
				});
				$('.daterange-picker').daterangepicker();
			},

			counter: function()
			{
				var self = this;

				$('.days-until').each(function() {
					var $this = $(this);
					var days = $this.attr('data-days');

					for (var i = 0; i < days.length; i++) {
						var $span = $('<span></span>');
						$span.text(days.charAt(i));
						$this.find('.until-count').append($span);
					}
				});
			},

			fav: function()
			{
				var self = this;

				$('.add-to-fav').click(function(e) {
					e.preventDefault();
					if(!$(this).hasClass('added')) {
						$(this).removeClass('removed').addClass('added');
					}
					else {
						$(this).removeClass('added').addClass('removed');
					}
				});
			},

			isEmail: function(email) 
			{
				var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
				return regex.test(email);
			},

			isPhone: function(phone) 
			{
				phone = phone.replace(/\s/g, "");
				let bool = true;
				let first = phone.substring(0,1);
				let rest = phone.substring(1);

				if(!((/^\d+$/.test(first)) || first == "+")) {
					bool = false;
				}

				if(!(/^\d+$/.test(rest))) {
					bool = false;
				}
				
				return bool;
			},

			Actions: {

				selectors: {
					btn: '.actions-btn'
				},

				init: function()
				{

					var self = this;

					$('body').on('click', self.selectors.btn, function() {
						var $this = $(this);
						$('.box-actions').not($this.parent('.box-actions')).removeClass('open');
						$('.box-actions').not($this.parent('.box-actions')).parents('tr').removeClass('focus');
						$this.parent('.box-actions').toggleClass('open');
						$this.parents('tr').toggleClass('focus');
					});


					//$(self.selectors.btn).clickoutside(function () {
					//	$('.box-actions').removeClass('open');
				    //		$('.box-actions').parents('tr').removeClass('focus');
					//});


					/*$(document).click(function(e) {
						if(e.target.className != 'actions-btn') {
							$('.box-actions').removeClass('open');
							$('.box-actions').parents('tr').removeClass('focus');
						}
					});*/
					$('.actions-btn').clickoutside(function() {
						$(this).closest('.box-actions').removeClass('open');
						$(this).closest('tr').removeClass('focus');
						//$('.box-actions').removeClass('open');
						//$('.box-actions').parents('tr').removeClass('focus');
					});

				}

			},

			Select: {
				open: false,

				isOpen: function(isOpen, elt)
				{
			       if(isOpen)
			          elt.addClass('open');
			       else
			          elt.removeClass('open');
			   },

				selectIsOpen: function()
				{
					var self = this;

					// if menu is open then true, if closed then false
				   // we start with false
				   var open = false;
				   // just a function to print out message

				   // on each click toggle the "open" variable
				   $("body").on("click", '.select-custom select', function() {
				         open = !open;
				         self.isOpen(open, $(this).closest('.select-custom'));
				         
				   });
				   // on each blur toggle the "open" variable
				   // fire only if menu is already in "open" state
				   $("body").on("blur", '.select-custom select', function() {
				         if(open){
				            open = !open;
				            self.isOpen(open, $(this).closest('.select-custom'));
				         }
				   });
				   // on ESC key toggle the "open" variable only if menu is in "open" state
				   $(document).keyup(function(e) {
				       if (e.keyCode == 27) { 
				         if(open){
				            open = !open;
				            self.isOpen(open, $(this).closest('.select-custom'));
				         }
				       }
				   });
				},
			},

			DropdownHeader: function() 
			{
				var self = this;

				$('body').on('click', '.dropdown-toggle-user', function(e) {
					e.preventDefault();
					$(this).toggleClass('open');
				});

				$('.dropdown.menu-user').clickoutside(function () {
					$(this).find('.dropdown-toggle-user').removeClass('open');
				});
			},

			Dropdown: {
				selectors: {
					container: '.ct-dropdown'
				},

				applyTo:
				'.select-custom select:not(.select2-hidden-accessible)'
				,
				//+ ', .row-filters select:not(.select2-hidden-accessible)'
				//+ ', .form-translation select',


				init: function()
				{
					var self = this;

					var time = 100;
					if($(self.applyTo).parents('.ct-search-filters').length > 0) {
						time = 500;
					}

					/*if($(".ct-dropdown").hasClass( "new_operation" )) {
						setTimeout(function() { self.create(self.applyTo); }, time);
					}*/

					$(window).on('load', function() {
						setTimeout(function() { self.create(self.applyTo); }, time);
					});

					$('body').on('change','.content #div_profil_ens_subform select', function() { 
						setTimeout(function() { 
							if(!$('.content #div_profil_ens_subform select').parent().hasClass('ct-dropdown')) { 
								self.create('.content #div_profil_ens_subform select'); 
							} 
						}, 500);
					});

					$(self.applyTo).each(function(){
						if($(this).prop('disabled') && !$(this).closest('.select-custom').hasClass('disabled')) {
							$(this).closest('.select-custom').addClass('disabled');
						}
					});

				},

				create: function(element)
				{
					var self = this;

					//$.each(self.applyTo, function(i, apply){
					//$(self.applyTo).each(function(i, apply) {
					$(element).each(function() {

						if($(this).attr('multiple') == 'multiple') { return; }

					    var $this = $(this), numberOfOptions = $(this).children('option').length;

					    if(numberOfOptions > 100) { return; }

					    $this.addClass('select-hidden');
					    $this.wrap('<div class="ct-dropdown"></div>');
					    $this.after('<div class="dropdown-label"></div>');

					    var $styledSelect = $this.next('div.dropdown-label');

					    $this.children('option').each(function() {
					    	if($(this).attr('selected') == 'selected') {
					    		$styledSelect.text($(this).text());
					    		return false;
					    	}
					    	else if($(this).closest('.select-one-empty').length && $this.children('option').eq(0).text() == '') {
					    		$styledSelect.text($this.closest('.select-one-empty').attr('data-empty'));
					    	}
					    	else {
						    	$styledSelect.text($this.children('option').eq(0).text());
						    }
					    });

					    var $list = $('<ul />', {
					        'class': 'dropdown-list'
					    }).insertAfter($styledSelect);

					    for (var i = 0; i < numberOfOptions; i++) {
					    	var t = $this.children('option').eq(i).text();
					    	if($(this).closest('.select-one-empty').length && $this.children('option').eq(i).text() == '') {
					    		t = $(this).closest('.select-one-empty').attr('data-empty');
					    	}
					        $('<li />', {
					            text: t,
					            rel: $this.children('option').eq(i).val()
					        }).appendTo($list);
					    }

					    var $listItems = $list.children('li');

					    $this.closest('.select-custom').addClass('customized');

					    //self.change($this);
					});

					self.change();
				},

				change: function()
				{
					var self = this;

					var $container = $(self.selectors.container);
					if($container.length < 1) { return; }

					$container.each(function() {

						var $dropdown = $(this);
						var $select = $(this).find('select');
						var $list = $dropdown.find('ul.dropdown-list');
						var $listItems = $list.children('li');

						var $styledSelect = $dropdown.find('.dropdown-label');
						$list.children('li').each(function() {
							if($(this).hasClass('active')) {
								$styledSelect.html($(this).text());
							}
						});

						$styledSelect.click(function(e) {
							e.stopPropagation();

							$('ul.dropdown-list').not($list).hide();
							$('.ct-dropdown').not($dropdown).removeClass('open');
							
							if($list.is(':hidden')) {
								$list.slideDown(200);
								$dropdown.addClass('open');
							}
							else {
								$list.slideUp(200);
								$dropdown.removeClass('open');
							}
						});

						$listItems.click(function(e) {
							e.stopPropagation();
							$listItems.removeClass('selected');
							$(this).addClass('selected');
							$styledSelect.html($(this).text()).removeClass('active');
							$dropdown.removeClass('open');
							$select.val($(this).attr('rel'));
							$list.hide();

							$select.change();
						});

						$(document).click(function() {
							$styledSelect.removeClass('active');
							$dropdown.removeClass('open');
							$list.hide();
						});

					});
				},
			},

			DropdownBis: {
				selectors: {
					elt: '.dropdown-bis'
				},

				init: function init() {
					var self = this;

					$(self.selectors.elt).find('.dropdown-label').click(function (e) {
						e.preventDefault();

						var $dropdown = $(this).parent(self.selectors.elt);
						if (!$dropdown.hasClass('open')) {
							$dropdown.find('.dropdown-list').slideDown(150);
							$dropdown.addClass('open');
						} else {
							$dropdown.find('.dropdown-list').slideUp(150);
							$dropdown.removeClass('open');
						}
					});

					$(self.selectors.elt).clickoutside(function () {
						$(this).removeClass('open').find('.dropdown-list').hide();
					});
				}
			},

			SelectSearch: {
				selectors: {
					elt : '.select-search select, .select-custom select'
				},

				init: function()
				{
					var self = this;

					let selectCount = 0;
					$(self.selectors.elt).each(function() {
						var $this = $(this);
						
						if($(this).attr('multiple') != 'multiple') { return; }

						var isMultiple = '';
						var closeOnSelect = true;
						var placeholder = $(this).attr('data-label');

						if($(this).attr('multiple') == 'multiple') {
							isMultiple = 'multiple';
							closeOnSelect = false;
						}
						$(this).select2({
		                    multiple: isMultiple,
		                    //placeholder: 'Sélectionner une région',
							placeholder: placeholder,
							closeOnSelect: closeOnSelect
		                }).on('select2:selecting', function(e) {
							$(e.currentTarget).data('scrolltop', $('.select2-results__options').scrollTop());
						})
						.on('select2:select', function(e) {
							$('.select2-results__options').scrollTop($(e.currentTarget).data('scrolltop'));
						})
						.on('select2:open', function(e) {
							if($this.find("option").length == 0) {
								$(".select2-results__options").addClass("empty");
							}
							else {
								$(".select2-results__options").removeClass("empty");
							}
						});

						$(this).on('select2:select', function (e) {
							// Do something
						  });

						var config = function() {
							$this.siblings('.select2').find('.select2-search--inline input').attr('placeholder', placeholder);
							if($this.val() != '') {
								$this.siblings('.select2').addClass('hasValues');
							}
							else {
								$this.siblings('.select2').removeClass('hasValues');
							}

							
						}

						config();

						$this.on('change', function() {
							config();
						});

						if($this.val() != '') {
							selectCount+= $this.find("option[selected]").length;
						}
		            });

					$(".group-filters-list .form-group .form-control").each(function() {
						if(!$(this).hasClass("select2-hidden-accessible") && $(this).closest(".hide").length < 1) {
							if($(this).val() != "") {
								$(this).closest('.form-group').addClass('hasValues');
								selectCount ++;
							}
						}
					});

					$(".cta-filters").find(".count").remove();
					if(selectCount > 0) {
						$('.filter-reset').css('display', 'inline-flex');
						$(".cta-filters").append("<span class='count'>" + selectCount + "</span>");
					}
				},
			},

			CheckboxList: {
				selectors: {
					checkbox: '.checkboxlist input[type=checkbox]',
				},

				init: function()
				{
					var self = this;

					self.check();
					self.remove();
					$(self.selectors.checkbox).each(function(){
						if($(this).prop('disabled') && !$(this).closest('.checkboxlist').hasClass('disabled')) {
							$(this).closest('.checkboxlist').addClass('disabled');
						}
						self.createList($(this));
					});
				},

				createList: function(elt)
				{
					var self = this;

					var $checklist = elt.parents('.checkboxlist').find('.check-list')
					var value = elt.val();
					var label = elt.parent().find('label[for="' + elt.attr('id') + '"]').text();
					/*if(elt.is(':checked')) {
						var $item = $('<div />', { 'class': 'checklist-item', 'data-value': value })
							.append('<div class="checklist-remove"></div>')
							.append(label)
							.appendTo($checklist);
					}*/
					if(elt.is(':checked')) {
						var $item = $('<div />', { 'class': 'checklist-item', 'data-value': value });
							if(!elt.prop('disabled')) {
								$item.append('<div class="checklist-remove"></div>');
							}
						$item.append(label).appendTo($checklist);
					}
					else {
						$checklist.find('[data-value="' + value + '"]').remove();
					}
				},

				check: function()
				{
					var self = this;

					$('form').on('change', self.selectors.checkbox, function(){
						self.createList($(this));
					});
				},

				remove: function(elt)
				{
					var self = this;

					$('body').on('click', '.checklist-remove', function() {
						var $item = $(this).parent('.checklist-item');
						$(this).parents('.checkboxlist').find('input[value="' + $item.attr('data-value') + '"]').prop('checked',false).change();
						$item.remove();
					});
				},
			},

			Quantity: {
				selectors: {
					elt: '.quantities',
				},

				init: function()
				{
					var self = this;

					$('.quantity-control').each(function() {
						var $container = $(this).closest('.quantities');
						var $input = $container.find('input');

						if($input.prop('disabled')) { $container.addClass('disabled'); }
					});

					$(document).on('keyup', '.quantities input', function(){
						let val = parseInt($(this).val());
						let min = parseInt($(this).attr("min"));
				        let max = parseInt($(this).attr("max"));
				        let newVal = val;
						
						if ( !val ){
							newVal = 0;
						}
						if ( val < min ){
							newVal = min;
						}
						if ( val > max ){
							newVal = max;
						}
						$(this).val(newVal);
					});

					$(document).on('click', '.quantity-control', function() {
						var $container = $(this).closest('.quantities');
						var $input = $container.find('input');

						if($input.prop('disabled')) { return; }

						/*var $plus = $(this).find('.quantity-plus'),
						$minus = $(this).find('.quantity-minus'),*/
						var min = $input.attr("min"),
				        max = $input.attr("max"),
				        valOfAmout = $input.val(),
				        newVal = 0;

				        // Click sur +
				        if($(this).hasClass('quantity-plus')) {
				        	var oldValue = parseFloat($input.val());
					        if (oldValue >= max) {
					          var newVal = oldValue;
					        } else {
					          var newVal = oldValue + 1;
					        }
					        $input.val(newVal);
					        $input.trigger("change");
				        }

				        // Click sur -
				        if($(this).hasClass('quantity-minus')) {
				        	var oldValue = parseFloat($input.val());
					        if (oldValue <= min) {
					          var newVal = oldValue;
					        } else {
					          var newVal = oldValue - 1;
					        }
					        $input.val(newVal);
					        $input.trigger("change");
				        }
					});
				},
			},

			Edition: {
				init: function()
				{
					var self = this;

					self.file();
				},

				file: function()
				{
					var self = this;

					$('.form-delete-file').click(function() {
						var id = $(this).attr('data-id');

						$('#' + id + ' + .btn-danger').click();
						$(this).closest('.form-file').remove();

					});
					//$('[data-id="news_attachments_0"]').click(function() { alert('ok'); $('#news_attachments_0 + .btn-danger').click() });
				},
			},

			// Function helping to get url param value
			urlParam: function(name)
			{
			    var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
			    if (results==null){
			       return null;
			    }
			    else{
			       return results[1] || 0;
			    }
			},
		},

		Content: {
			selectors: {},

			init: function()
			{
			  	var self = this;
  	
  				self.changeTypeOpe();
			  	self.Filter.init();
			  	self.Operation.init();
			  	self.Steps.init();
			  	self.Command.init();
			  	self.CommandList.init();
				self.TableFixed.init();
				self.Quotas.init();
				self.RegionTree.init();
				self.Comments.init();
				self.CheckForm.init();
				self.Dashboard.init();
				self.showList();
				
				/*
				$('.form-ope-duration input').change(function() {
					if($(this).is(':checked')) {
						$(this).parent('.form-ope-duration').siblings('.form-ope-duration').find('input').prop('checked', false);
					}
				});*/
			},

			showList: function() {
				$(".check-list-collapse .show-all").click(function() {
					$(this).closest(".check-list-collapse").removeClass("collapsed");
				});
			},

			changeTypeOpe: function()
			{
				var self = this;

				$('.sidebar-filters form input, .sidebar-filters form select').change(function(e) {
					e.preventDefault();
					$(this).parents('form').submit();
				});
			},

			Filter: {
				selectors: {
					filters: '.row-filters',
					content: '.row-content',
				},

				init: function()
				{
					var self = this;

					self.filter();
					self.searchByName();
					self.filterMobile();
					
				},

				searchByName: function()
				{
					var self = this;

					$('body').on('click', '.submit-autocomplete-field', function() {
						$(this).closest('form').submit();
					});
				},

				filter: function()
				{
					var self = this;

					//if($(self.selectors.filters).length < 1 && $(self.selectors.content).length < 1) { return; }
					if(!$('.row-filters').length) { return; }
					if($('.row-filters').length && $('.row-filters').hasClass('command-filters')) { return; }

					if(window.location.search != '') {
						$('.filter-reset').css('display', 'inline-flex');
					}

					$('body').on('change', 'select, input[type="date"]', function() {
						if($(this).closest('.noajax').length) { return; }
						var $form = $(this).parents('form');
						var url = window.location.origin + window.location.pathname;

						/*if($(this).parents('.period-select').length > 0 && $(this).val() == '1') { 
							return; 
						}*/

						$.ajax({
							type: "GET",
							url: url,
							data: $form.serialize(),
							beforeSend: function() {
								$('.row-content').addClass('loading');
							},
							success: function(data) {
								$('#totalOnPage').replaceWith($(data).find('#totalOnPage'));
								$('.row-filters').replaceWith($(data).find('.row-filters'));
								$(self.selectors.content).replaceWith($(data).find('.row-content'));
								$("#default_filter_list_shop").html($(data).find("#default_filter_list_shop").html());
								$('.row-footer').replaceWith($(data).find('.row-footer'));
								//$('.pagination').replaceWith($(data).find('.pagination'));
								//$('.row-filters .filter-reset').css('display', 'inline-block');
								$('.row-filters').addClass('filtered');
								if($('.period-select select').val() == '0') {
									$('.period-date').addClass('disabled');
									$('.period-date input').attr('disabled','disabled');
								}
								if($('.period-select select').val() == '1') {
									$('.period-date').removeClass('disabled');
									$('.period-date input').removeAttr('disabled');
								}
								if($('.period-select select').val() == '2') {
									$('.period-date').removeClass('disabled');
									$('.period-date input').removeAttr('disabled');
								}

								$('body').addClass('filters-open');//.find('.group-filters-list').show();
							}
						});
					});
				},

				filterMobile: function()
				{
					var self = this;

					$('body').on('click', '.cta-filters', function(e) {
						e.preventDefault();

						if(!$('body').hasClass('filters-open')) {
							$('body').addClass('filters-open');//.find('.group-filters-list').slideDown();
						}
						else {
							$('body').removeClass('filters-open');//.find('.group-filters-list').slideUp();
						}
					});

					$('body').on('click', '.cta-close-filters', function(e) {
						e.preventDefault();
						$('body').removeClass('filters-open');//.find('.group-filters-list').slideUp();
					});
				},
			},

			Operation: {
				selectors: {
					container: '.animation-item',
					slider:'.product-slider',
				},

				viewModeAjax: null,
				hasAjax: false,

				init: function() 
				{
					var self = this;

					self.addOperation();
					self.size();
					self.hover();
					self.viewMode();
					self.detail();
					self.datesTabs();
					//self.openCommand();
					//self.slider();
					self.resetDate();
				},


				addOperation: function() 
				{
					var self = this;

					$('.ct-dropdown-bis .dropdown-label-bis').click(function (e) {
						e.preventDefault();

						var $dropdown = $(this).closest('.ct-dropdown-bis');
						if (!$dropdown.hasClass('open')) {
							$dropdown.find('.dropdown-list-bis').slideDown(150);
							$dropdown.addClass('open');
						} else {
							$dropdown.find('.dropdown-list-bis').slideUp(150);
							$dropdown.removeClass('open');
						}
					});

					$('.dropdown-list-bis li a').click(function(e) {
						//e.stopPropagation();
						$(this).addClass('selected');
						$(this).closest('.ct-dropdown-bis').find('.dropdown-label-bis').html($(this).text());
						$(this).closest('.ct-dropdown-bis').removeClass('open').find('.dropdown-list-bis').hide();
					});

					$('.ct-dropdown-bis').clickoutside(function () {
						$(this).removeClass('open').find('.dropdown-list-bis').hide();
					});
				},

				size: function()
				{
					var self = this;

					$('.animation-teaser').each(function() {
						$(this).parents(self.selectors.container).css('min-height', $(this).outerHeight() + $(this).parents().find('.animation-head').outerHeight());
					});
				},

				viewMode: function()
				{
					var self = this;

					var viewModeDefault = AppGeneral.Cookie.read('viewMode');
					//console.log(viewModeDefault);

					/* CODE DU COOKIE A CONSERVER 

					var viewModeDefault = AppGeneral.Cookie.read('viewMode');
					
					if(viewModeDefault == 'small' || viewModeDefault == 'large') {
						$('.switchable-view a[data-view="' + viewModeDefault + '"]').addClass('active').siblings().removeClass('active');
						$('.animation-list').removeClass('small-list large-list').addClass(viewModeDefault + '-list');

						$('.animation-teaser').each(function() {
							var minHeight = 0;
							if(viewModeDefault == "large") {
								minHeight = $(this).parents(self.selectors.container).css('min-height', $(this).outerHeight() + $(this).parents().find('.animation-head').outerHeight());
							}
							$(this).parents(self.selectors.container).css('min-height', minHeight);
						});
					}*/ 


					/*var urlMode = window.location.origin + window.location.pathname;
					if(AppGeneral.Tools.urlParam('row-design') == 'full') {
						urlMode += '?row-design=line';
					}
					else {
						urlMode += '?row-design=full';
					}*/

					$('.switch-view-ope a').click(function(e) {
						var $this = $(this);

						e.preventDefault();
						$(this).addClass('active').siblings().removeClass('active');
						$('.animation-list').removeClass('loading');

						var values = '';
						/*$('.operation-filters form select').each(function() {
							if($(this).val() != '') {
								values = $('.operation-filters form').serialize();
								return false;
							}
						});*/

						for (i = 0; i < $('.operation-filters form').serializeArray().length; i++) {
							if($('.operation-filters form').serializeArray()[i].name != 'default_filter_list[_token]') {
								if($('.operation-filters form').serializeArray()[i].value != '') { 
									values = $('.operation-filters form').serialize();
									break; 
								}
							}
						}

						// Si il n'y a pas de filtres
						/*if(values == '') {
							if(window.location.search != '') {
								urlMode += window.location.search;
							}
                        }*/
                        
                        var urlMode = window.location.origin + window.location.pathname + window.location.search,
                            param = '?';

                        if(window.location.search != '') {
                            param = '&';
                        }
						//if(AppGeneral.Tools.urlParam('row-design') == 'full') {
						if($(this).hasClass('small-view')) {
							urlMode += param + 'row-design=line';
						}
						if($(this).hasClass('large-view')) {
							urlMode += param + 'row-design=full';
						}

						if(self.viewModeAjax) { self.viewModeAjax.abort(); }
	                    self.viewModeAjax = $.ajax({
	                        url: urlMode,
	                        type: 'GET',
	                        //data: data,
	                        //data: $('.operation-filters form').serialize(),
	                        data: values,
	                        beforeSend: function() {
	                        	if(self.hasAjax) {
	                            	setTimeout(function() { $('.row-content').addClass('loading'); }, 300);	
	                            }
	                            else {
	                            	$('.row-content').addClass('loading');
	                            	self.hasAjax = true;
	                            }
	                        },
	                        success: function success(html) {
	                        	$('.row-content').replaceWith($(html).find('.row-content'));
	                        	$('.header_page').replaceWith($(html).find('.header_page'));

	                        	$('.row-content .actions-btn').clickoutside(function() {
									$(this).closest('.box-actions').removeClass('open');
									$(this).closest('tr').removeClass('focus');
									//$('.box-actions').removeClass('open');
									//$('.box-actions').parents('tr').removeClass('focus');
								});
	                        }
	                    });

	                    AppGeneral.Cookie.create('viewMode', $this.attr('data-view'));

						/*$('.animation-list').removeClass('small-list large-list').addClass($this.attr('data-view') + '-list');

						AppGeneral.Cookie.create('viewMode', $this.attr('data-view'));

						$('.animation-teaser').each(function() {
							var minHeight = 0;
							if($this.attr('data-view') == "large") {
								minHeight = $(this).parents(self.selectors.container).css('min-height', $(this).outerHeight() + $(this).parents().find('.animation-head').outerHeight());
							}
							$(this).parents(self.selectors.container).css('min-height', minHeight);
						});*/


					});
				},

				hover: function()
				{
					var self = this;

					$('.animation-row .table-custom > tbody > tr').hover(function() {
						$(this).parents('.animation-item').addClass('hover');
					},
					function() {
						$(this).parents('.animation-item').removeClass('hover');
					});
				},

				detail: function()
				{
					var self = this;

                    // Ancien clic sur "Plus de détails" en vue détaillée
                    
					/*$('body').on('click', '.open-animation', function(e) {
						e.preventDefault();

						var $this = $(this);
						if(!$this.parents('.animation-item').hasClass('open') && $this.attr('data-isopen') != 'yes') {
							$('.animation-item').find('.animation-more a').attr('data-isopen','no');
							$this.parents('.animation-item').find('.animation-more a').attr('data-isopen','yes');
							$this.parents('.animation-item').find('.animation-content').slideDown(function() {
								self.sliderOn($this.parents('.animation-item').find('.animation-content').find(self.selectors.slider));
							});

							$this.parents('.animation-item').addClass('open').siblings('.animation-item').removeClass('open');
							
							$('.animation-content').not($this.parents('.animation-item').find('.animation-content')).slideUp(function() {
								self.sliderOff($(this).find(self.selectors.slider));
							});
						}
						else if($this.attr('data-isopen') == 'yes') {
							$(self.selectors.container).find('.close-animation').click();
						}
					});*/

					$('body').on('click', '.close-animation', function(e) {
						e.preventDefault();

						var $item = $(this).parents('.animation-item');
						$item.removeClass('open');
						$item.find('[data-isopen]').attr('data-isopen', 'no');
						$item.find('.animation-content').slideUp(function() {
							//$item.parents('.animation-item').find('.animation-teaser').fadeIn(300);
							self.sliderOff($item.find('.animation-content').find(self.selectors.slider));
						});

					});

					/*$(self.selectors.container).find('.btn-see-details').click(function(e) {
						e.preventDefault();

						var $this = $(this);
						$this.parents('.table-content').removeClass('command-opened');

						
						
						$this.parents('.table-content').find('.bloc-command').slideUp(800);

						
						$this.parents('.table-content').find('.bloc-full').slideDown(800, function() {
							$this.parents('.table-content').find('.bloc-teaser').fadeOut(300, function() {
								$this.hide();
								$this.parents('.table-content').find('.animation-command').show();
							});
							self.sliderOn($this.parents('.table-content').find(self.selectors.slider));
							$this.parents('.table-content').addClass('open detail-opened');
						});
						
					})*/
				},

				openCommand: function()
				{
					var self = this;

					$(self.selectors.container).find('.btn-command').click(function(e) {
						e.preventDefault();

						var $this = $(this);
						$this.parents('.table-content').removeClass('detail-opened');

						$this.parents('.table-content').find('.bloc-full').hide();

						$this.parents('.table-content').find('.bloc-teaser').fadeIn(300);
							self.sliderOff($this.parents('.table-content').find(self.selectors.slider));

						if(!$this.parents('.table-content').hasClass('command-opened')) {
							$this.parents('.table-content').find('.btn-see-details').css('display', 'inline-block');
							$this.parents('.table-content').find('.animation-command').fadeOut(300);
							$this.parents('.table-content').find('.bloc-command').slideDown(800);
							$this.parents('.table-content').addClass('open command-opened');
						}
						else {
							$this.parents('.table-content').find('.btn-see-details').fadeOut(800);
							$this.parents('.table-content').find('.bloc-full').slideUp(800, function() {
								$this.parents('.table-content').find('.animation-command').fadeIn(300);
								self.sliderOff($this.parents('.table-content').find(self.selectors.slider));
							});
							$this.parents('.table-content').removeClass('open command-opened');
						}
					});

				},

				sliderOn: function(elt)
				{
					var self = this;

					if(elt.length < 1) { return; }

					//elt.each(function() {
						//var $this = $(this);

						elt.find('.carousel-for').slick({
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: true,
							fade: true,
							asNavFor: elt.find('.carousel-nav')
						});
						elt.find('.carousel-nav').slick({
							slidesToShow: 4,
							slidesToScroll: 1,
							asNavFor: elt.find('.carousel-for'),
							dots: false,
							arrows: true,
							centerMode: true,
							focusOnSelect: true,
							centerPadding:0,
						});
					//});
				},

				sliderOff: function(elt)
				{
					var self = this;

					elt.find('.slick-slider').slick('unslick');
				},

				datesTabs: function()
				{
					var self = this;

					$('.dates-tabs li a').click(function(e) {
						e.preventDefault();

						var type = $(this).attr('data-type');
						$(this).closest('li').addClass('active').siblings('li').removeClass('active');
						$('.dates-item').hide().siblings('[data-type="' + type + '"]').fadeIn();
					});
				},

				resetDate: function()
				{
					$('.resettable').each(function() {
						if($(this).find('input').val() != '') {
							$(this).find('.reset-date-field').show();
						}
					});

					$('.resettable').find('input').change(function() {
						if($(this).val() != '') {
							$(this).closest('.resettable').find('.reset-date-field').show();
						}
						else {
							$(this).closest('.resettable').find('.reset-date-field').hide();
						}
					});

					$('.reset-date-field').click(function() {
						$(this).closest('.resettable').find('input').val('').removeAttr('value');
						$(this).hide();
					});
				}
				
			},

			Steps: {
				selectors: {
					container: '.step-container',
				},

				init: function()
				{
					var self = this;

					$(self.selectors.container).each(function() {

						var $container = $(this);
						var $btnNext = $container.find('.next-step');
						var $btnPrev = $container.find('.prev-step');

						$btnNext.click(function(e) {
							e.preventDefault();
							var $fields = $(this).closest('form').find('.bloc-step[data-step="' + $(this).attr('data-step') + '"]').find('.checkable input, .checkable select');

							var valid = true;

							$('.field-error, .command-error').hide();
							$('#operation_order_agency').closest('.select-custom').removeClass('error').find('.command-error').remove();
							$('#operation_order_shop').closest('.select-custom').removeClass('error').find('.command-error').remove();

							$fields.each(function() {
								if($(this).prop('required') && $(this).val() == '') {
									valid = false;
									if($(this).parents('.checkable').length > 0) {
										$(this).parents('.checkable').addClass('error').siblings('.field-error').show();
									}
								}
							});
							// Agency
							if($('#operation_order_agency').length && $('#operation_order_agency').val() == '') {
								valid = false;
								$('#operation_order_agency').closest('.select-custom').addClass('error').after('<div class="command-error" style="display:block;">' + $('.error-field-text').attr('data-text') + '</div>');
							}
							// Shop
							if($('#operation_order_shop').length && $('#operation_order_shop').val() == '') {
								valid = false;
								$('#operation_order_shop').closest('.select-custom').addClass('error').after('<div class="command-error" style="display:block;">' + $('.error-field-text').attr('data-text') + '</div>');
							}

							// Address
							if($btnNext.attr("data-step") == 2 && $('#operation_order_furniture_shopInfoAddress').length && $('#operation_order_furniture_shopInfoAddress').val() == '') {
								valid = false;
								$('#operation_order_furniture_shopInfoAddress').closest(".shop-info").addClass("error");
								$('.checkable').addClass('error').siblings('.error-info-empty').show();
							}

							// Shop contact name
							if($btnNext.attr("data-step") == 2 && $('.shop-contact-name input').prop('required')) {
								if($('.shop-contact-name input').val() == '') {
									valid = false;
									$('.shop-contact-name').addClass("error");
									$('.checkable').addClass('error').siblings('.error-info-empty').show();
								}
							}

							// Phone
							if($btnNext.attr("data-step") == 2 && $('.shop-phone input').prop('required')) {
								if($('.shop-phone input').val() == '') {
									valid = false;
									$('.shop-phone').addClass("error");
									$('.checkable').addClass('error').siblings('.error-info-empty').show();
								}
							}
							if($('.shop-phone input').length > 0 && $('.shop-phone input').val() != '') {
								if(!AppGeneral.Tools.isPhone($('.shop-phone input').val())) {
									valid = false;
									if($('.checkable').length > 0) {
										$('.shop-phone').addClass("error");
										$('.checkable').addClass('error').siblings('.error-phone').show();
									}
								}
							}

							// Email
							if($btnNext.attr("data-step") == 2 && $('.shop-email input').prop('required')) {
								if($('.shop-email input').val() == '') {
									valid = false;
									$('.checkable').addClass('error').siblings('.error-info-empty').show();
								}
							}
							if($('.shop-email input').length > 0 && $('.shop-email input').val() != '') {
								if(!AppGeneral.Tools.isEmail($('.shop-email input').val())) {
									valid = false;
									if($('.checkable').length > 0) {
										$('.checkable').addClass('error').siblings('.error-mail').show();
									}
								}
							}

							// Type d'action
							if($('[name="operation_order[actionType]"]').length && $('[name="operation_order[actionType]"]:checked').length == 0) {
								valid = false;
								$('[name="operation_order[actionType]"]').closest('.form-group').addClass('error').append('<div class="command-error" style="display:block;">' + $('.error-field-text').attr('data-text') + '</div>');
							}

							// Category
							if($('[name="operation_order[category]"]').length && $('[name="operation_order[category]"]:checked').length == 0) {
								valid = false;
								$('[name="operation_order[category]"]').closest('.form-group').addClass('error').append('<div class="command-error" style="display:block;">' + $('.error-field-text').attr('data-text') + '</div>');
							}

							// Envoy present
							if($('[name="operation_order[envoyPresent]"]').length && $('[name="operation_order[envoyPresent]"]:checked').length == 0) {
								valid = false;
								$('[name="operation_order[envoyPresent]').closest('.form-group').addClass('error').append('<div class="command-error" style="display:block;">' + $('.error-field-text').attr('data-text') + '</div>');
							}

							// Attachment file
							if($('#operation_order_orderAttachmentFile_file').length) {
								if($('[name="operation_order[orderAttachmentFile][file]"]').attr('required') == "required" && $('[name="operation_order[orderAttachmentFile][file]"]').val() == "" && $('#operation_order_orderAttachmentFile_delete').length < 1) {
									valid = false;
									$('[name="operation_order[orderAttachmentFile][file]"]').addClass('error');
									$('.error-attachment').show();
								}
								else if($('[name="operation_order[orderAttachmentFile][file]"]').val() != "" && document.getElementById('operation_order_orderAttachmentFile_file').files[0].size / 1024 / 1024 > 2){
									valid = false;
									$('[name="operation_order[orderAttachmentFile][file]"]').addClass('error');
									$('.error-attachment-size').show();
								}
							}
							//nbRefrigeratorBin
							if($('.nb-refrigerator').length && $('.next-step').attr('data-step') == 2) {
								valid = false;
								$('.nb-refrigerator').each(function () {
									if ( $(this).val() > 0){
										valid = true;
									}
								});
							}

							//nbFurniture
							if($('.nb-furniture').length && $('.next-step').attr('data-step') == 3) {
								valid = false;
								$('.nb-furniture').each(function () {
									if ( $(this).val() > 0){
										valid = true;
									}
								});
							}

							if(!valid && $('.error').length) {
								$('html, body').animate({ 'scrollTop' : $('.error').offset().top - 20 });
							}

							if(valid) {
								$(".furniture-container .operation-filters.step-1").hide();
								$('.error').removeClass('error');
								$('[name="operation_order[orderAttachmentFile][file]"]').removeAttr('required');
								$('.checkable').removeClass('error').siblings('.field-error, .command-error').hide();
								$container.find('.step-tabs .step-item.active + .step-item').addClass('clickable');

								var step = parseInt($(this).attr('data-step')) + 1;
								$container.find('.bloc-step[data-step="' + step + '"]').fadeIn().siblings('.bloc-step').hide();
								$container.find('.step-tabs .step-item[data-step="' + step + '"]').addClass('active').siblings('.step-item').removeClass('active');

								$(this).attr('data-step', step);
								if($container.find('.bloc-step:last').attr('data-step') == step) {
									$btnNext.hide();
									$('.save-draft').show();
									$('.footer-actions').css('display', 'table');
								}

								//$('.close-edit').click();

								$btnPrev.css('display', 'inline-block');
								if($('.step-container').length) {
									$(window).scrollTop($('.step-container').offset().top - 20);
								}
								else {
									$(window).scrollTop(0);
								}
							}
						});


						$btnPrev.click(function(e) {
							e.preventDefault();

							var step = parseInt($btnNext.attr('data-step'));

							if(step == 2) {
								$btnPrev.hide();
								$(".furniture-container .operation-filters.step-1").show();
								$('.footer-actions').hide();
								$('.furniture-container-wrapper').addClass("refresh");
							}

							$container.find('.bloc-step[data-step="' + (step - 1) + '"]').fadeIn().siblings('.bloc-step').hide();
							$container.find('.step-tabs .step-item[data-step="' + (step - 1) + '"]').addClass('active').siblings('.step-item').removeClass('active');

							$btnNext.attr('data-step', step - 1);
							$btnNext.css("display", "inline-block");
							$('.save-draft').hide();
							$('.footer-actions').hide();

							if($('.step-container').length) {
								$(window).scrollTop($('.step-container').offset().top - 20);
							}
							else {
								$(window).scrollTop(0);
							}
						});


						$container.find('.step-tabs .step-item').click(function() {
							if(!$(this).hasClass('clickable')) { return; }

							if($(this).prevAll('.active').length > 0) {
								$btnNext.click();
							}

							var step = $(this).attr('data-step');
							$container.find('.bloc-step[data-step="' + step + '"]').fadeIn().siblings('.bloc-step').hide();
							$(this).addClass('active').siblings('.step-item').removeClass('active');

							$container.find('.next-step').attr('data-step', step);
							if($(this).next().length < 1) {
								$btnNext.hide();
								$('.save-draft').show();
								$('.footer-actions').css('display', 'table');
							}
							else {
								$btnNext.css("display", "inline-block");
								$('.save-draft').hide();
								$('.footer-actions').hide();
							}

							if($(this).prev().length < 1) {
								$btnPrev.hide();
								$('.save-draft').hide();
								$('.footer-actions').hide();
								$(".furniture-container .operation-filters.step-1").show();
								$('.furniture-container-wrapper').addClass("refresh");
							}

							if(step != 1) {
								$(".furniture-container-wrapper").removeClass("refresh");
							}
						});

					});

					$("body").on("focus", ".shop-info input", function() {
						$(this).closest(".shop-info").removeClass("error");
					});
				},
			},

			Command: {
				selectors: {
					dates: '.table-dates',
				},

				init: function()
				{
					var self = this;

					self.editContact();
					self.date();
					self.quit();
					
					$('.clear-before-command').click(function(e) {
						e.preventDefault();
						let href = $(this).attr("href");
						let id = $(this).attr("data-id");
						let urlClear = `/orderapi/furniture-reset-cart/${id}`

						if($(this).hasClass("clear-2")) {
							urlClear = `/orderapi/furniture-reset-cart2/${id}`
						}

						AppGeneral.Content.Command.clearFurnitures(urlClear, href);
					});
				},

				editContact: function()
				{
					var self = this;

					$('body').on('click','.open-edit', function(e) {
						e.preventDefault();

						$(this).closest('.contact-command-field').addClass('edit');
						//var val = $(this).parents('.contact-command-field').find('textarea').val();
						//$(this).parents('.contact-command-field').find('textarea').focus().val('').val(val);
						$(this).closest('.contact-command-field').find('input').removeAttr('disabled');
						$(this).closest('.contact-command-field').find('input:first').focus();
					});
					$('body').on('click','.close-edit', function(e) {
						e.preventDefault();

						$(this).closest('.contact-command-field').removeClass('edit');
						//var update = $(this).parents('.contact-command-field').find('textarea').val();
						//update = update.replace(/\n\r?/g, '<br />');
						//$(this).parents('.contact-command-field').find('span').html(update);
						$(this).closest('.contact-command-field').find('input').attr('disabled', 'disabled');
					});
				},

				date: function()
				{
					var self = this;
					/*
					$(self.selectors.dates).each(function() {
						var $table = $(this);

						$table.find('.form-dates input').change(function() {
							var $parent = $(this).parents('.ct-form-dates');

							if($('.d-start').val() != '' && $('.d-end').val() != '') {
								// Nombre d'heures
								var dStart = Date.parse($('.d-start').val());
								var dEnd = Date.parse($('.d-end').val());

							
								var nbHours = parseInt((dEnd - dStart) / 1000 / 60 / 60);
								var nbDays = parseInt((dEnd - dStart) / 1000 / 60 / 60 / 24);

								$parent.find('.diff-dates').text(nbDays);
							}
						});
					});
					*/
					$('.ct-form-dates input').attr('autocomplete', 'off');

					$(document).on('change', '.command-page.editing .step-3 .recoveryDate, .command-page.editing .step-4 .recoveryDate', function(){
						let newDateParts = $(this).val().split("-");
						let newDate = new Date(+newDateParts[2], newDateParts[1] - 1, +newDateParts[0]);
						let oldDateParts = $(this).attr('placeholder').split("-");
						let oldDate = new Date(+oldDateParts[2], oldDateParts[1] - 1, +oldDateParts[0]);
						let isRecoverable = parseInt($(this).parent().attr('data-is-recoverable'));
						let isExtendable = parseInt($(this).parent().attr('data-is-extendable'));

						if ( isNaN(newDate) || isNaN(oldDate) ){
							return;
						}

						if ( newDate > oldDate && isExtendable ){
							$(this).closest('tr').find(".prolongation").removeClass('hidden');
						} else {
							$(this).closest('tr').find(".prolongation").addClass('hidden');
						}
						if ( newDate < oldDate && isRecoverable ){
							$(this).closest('tr').find(".reprise").removeClass('hidden');
						} else {
							$(this).closest('tr').find(".reprise").addClass('hidden');
						}
					})
				},

				quit: function()
				{
					if($('.draft-alert').length) {
						$('.navbar-custom-menu a, .treeview-menu a, .link-back, .box-actions a').click(function(e) {
							if($(this).attr('href') != '#') {
								e.preventDefault();
								let href = $(this).attr('href');
								let r = confirm($('.draft-alert').attr('data-alert'));
								if(r) {
									if(document.querySelector(".command-page.furniture")) {
										let id = document.querySelector(".command-page.furniture").getAttribute("data-id");
										let urlClear = `/orderapi/furniture-reset-cart/${id}`;
										if(document.querySelector(".command-page.furniture").classList.contains("editing")) {
											urlClear = `/orderapi/furniture-reset-cart2/${id}`
										}
										AppGeneral.Content.Command.clearFurnitures(urlClear, href);
									}
									else {
										window.location = href;
									}
								}
								else {
									return false;
								}
							}
						});
					}
				},

				clearFurnitures: function(urlClear, href) {
					$.ajax({
						url: window.location.origin + urlClear,
						beforeSend: function() {
							$('.content-wrapper .content').addClass('loading');
						},
						success: function success() {
							window.location = href;
						},
						error: function(response) {
							$('.content-wrapper .content').removeClass('loading');
							alert(JSON.stringify(response));
						}
					});
				}
			},

			CommandList: {
				selectors: {},

				init: function()
				{
					var self = this;

					//$('.table-resize').resizableColumns();
					//var widths = [];
					$('.table-resize th').each(function(i) {
						$(this).attr('data-width', $(this).innerWidth());
			        	//$(this).innerWidth($(this).innerWidth());
			        });

			        $('.table-resize th.th-shop').addClass('open');

			        var timeout = '';

			        $('body').on('click', '.table-resize th .bigger', function() {
			        	var $th = $(this).parent('th');
			        	
			        	if(!$th.hasClass('open')) {
			        		clearTimeout(timeout);
			        		$th.attr('data-width', $th.innerWidth());
			        		$th.css('width', $th.attr('data-width'));
			        		timeout = setTimeout(function() { $th.addClass('open').siblings('th').removeClass('open'); }, 100);
			        	}
			        	else {
			        		$th.removeClass('open').siblings('th').removeClass('open');
			        		clearTimeout(timeout);
			        		timeout = setTimeout(function() { $th.removeAttr('style'); }, 400);
			        	}
			        });
				},
			},

			TableFixed: {
				selectors: {
					container: '.table-container',
				},

				init: function()
				{
					var self = this;

					$(self.selectors.container).each(function() {
						var $container = $(this);
						$container.find('.table-empty').css({
							'min-width': $container.find('th:first-child').outerWidth(),
							'min-height' : $container.find('th').outerHeight()
						});

						$container.find('.table-cell').css({
							'min-width': $container.find('td:first-child').outerWidth(),
							'min-height' : $container.find('td').outerHeight()
						});

						$container.addClass('view');
					});
				},
			},

			Quotas: {
				selectors: {
					'form' : 'form[name="quota_operation"]'
				},
				ajaxQuota: null,
				allOpen: false,
				initialState: {},
				halfDay: false,

				init: function()
				{
					var self = this;

					self.initialState = $(self.selectors.form).serialize();
					self.halfDay = ( $('.quota-container.quota-unity-halfday').length > 0 );
					$('.quota-btn.all').text($('.quota-btn.all').attr('data-open'));

					if ( $('li.national > ul').length == 0) {
						$('li.national .quota-head > span .ct-qAssigned').remove();
					}

					self.slide();
					//self.allocate();
					self.counts();
					self.totalCount();

					// Retour aux détails de l'opération
					$('.back-from-quotas').click(function(e) {				
						if( self.formHasChanged() ) {
							e.preventDefault();
							var r = confirm($(this).attr('data-alert'));
							if(r) {
								window.location = $(this).attr('href');
							}
							else {
								return false;
							}
						}
					});

					// changement d'onglet
					$('.swith-quotarefbin a, #change-refrigerator-bin a, .swith-quotaref a, #change-ref-item a').click(function(e){
						if( self.formHasChanged() ) {
							e.preventDefault();
							var r = confirm($(this).attr('data-alert'));
							if(r) {
								window.location = $(this).attr('href');
							}
							else {
								return false;
							}
						}
					});

					// Au changement de valeur input
					var timeoutQuota;
					$('body').on('focusout', '.quota-assigned input', function() {
						var $this = $(this);

						clearTimeout(timeoutQuota);
						timeoutQuota = setTimeout(function() {
							self.totalCount();
							self.counts();
							self.addition($this);
						}, 10);
					});

					// focus sur input
					$('body').on('focusin', '.quota-inputs input', function(){
						$(this).removeClass('error').closest('.quota-head').find('.quota-error').removeAttr('data-' + $(this).attr('data-quota'));
					});

					// Répartir équitablement
					$('body').on('click', '.quota-allocate', function(e) {
						e.preventDefault();

						if(!$(this).closest('.quota-head').siblings('ul').length && $(this).closest('.quota-item').attr('data-qid') != '') {
							//self.addChildren($(this), true);
						}
						else {
							self.allocate($(this));
						}
					});

					// Répartir équitablement les niveaux ascendants
					$('body').on('click', '.quota-allocatenivs', function(e) {
						e.preventDefault();
						self.allocatenivs($(this));
					});

					// Submit du formulaire
					$('[name="quota_operation"]').submit(function() {
						var valid = true;
						$('.quota-inputs input').each(function() {
							if(self.addition($(this))) {
								alert($('.error-repartition').attr('data-text'));
								valid = false;
								return false;
							}
						});
						if(!valid) return false;
					});
				},

				formHasChanged: function(){
					var self = this;

					return ( self.initialState != $(self.selectors.form).serialize() );
				},

				addChildren: function(elt, allocate)
				{
					var self = this;

					var $this = elt;

					if(self.ajaxQuota) { self.ajaxQuota.abort(); }
					self.ajaxQuota = $.ajax({
                        url: window.location.origin + '/quota-childrens/' + $this.closest('.quota-item').attr('data-qid'),
                        type: 'GET',
                        beforeSend: function() {
                            $('.quota-container > .items').addClass('loading');
                        },
                        success: function success(html) {
                            //var children = $(html).find('body > ul');
                            if(html == '') { $this.remove(); }
                            $this.closest('.quota-head').after(html);
                            $this.closest('.quota-head').siblings('ul').slideToggle().closest('.quota-item').toggleClass('open');
                            $('.quota-container > .items').removeClass('loading');
                            if(allocate) {
                            	self.allocate($this);
                            }
                        }
                    });
					
				},

				addAllChildren: function(elt)
				{
					var self = this;

					/*elt.each(function(i) {
						var $this = $(this);

						$('.quota-container > .items').addClass('loading');
						i = $.ajax({
	                        url: window.location.origin + '/quota-childrens/' + $this.attr('data-qid'),
	                        type: 'GET',
	                        beforeSend: function() {
	                            $('.quota-container > .items').addClass('loading');
	                        },
	                        success: function success(html) {
	                            //var children = $(html).find('body > ul');
	                            //if(html == '') { $(this).remove(); }
	                            $('.quota-container > .items').removeClass('loading');
	                            if(html != '') {
		                            $this.find('> .quota-head').after(html);
		                            $this.find('> .quota-head').siblings('ul').slideToggle();
									$this.toggleClass('open');
	                            	

	                            	i.abort();
	                            	self.addAllChildren($this.find('.quota-item'));
	                            }        
                        	}
                    	});

					});*/

					if(self.ajaxQuota) { self.ajaxQuota.abort(); }
					self.ajaxQuota = $.ajax({
	                    url: window.location.href + '?allNiv=1',
	                    type: 'GET',
	                    beforeSend: function() {
	                        $('.quota-container > .items').addClass('loading');
	                    },
	                    success: function success(html) {
                            $('.quota-container > .items').replaceWith($(html).find('.quota-container > .items'));
                            $('.national .quota-item').each(function() {
								var $item = $(this);
								$item.addClass('open').find('> ul').slideDown();
								$('.quota-btn.all').text($('.quota-btn.all').attr('data-close'));
							});     
	                	}
	            	});

				},

				slide: function()
				{
					var self = this;

					/*$('body').on('focus', '.quota-inputs input', function() {
						if(!$(this).closest('.quota-head').siblings('ul').hasClass('open')) {
							$(this).closest('.quota-head').siblings('ul').slideDown().closest('.quota-item').addClass('open');
						}
					});*/

					$('body').on('click', '.quota-container .quota-btn', function() {
						if(!$(this).hasClass('all')) {
							if(!$(this).closest('.quota-head').siblings('ul').length && $(this).closest('.quota-item').attr('data-qid') != '') {
								//self.addChildren($(this));
							}
							else {
								$(this).closest('.quota-head').siblings('ul').slideToggle().closest('.quota-item').toggleClass('open');
							}
						}
						else {
							//$(this).closest('.quota-head').siblings('ul').slideToggle().closest('.quota-item').toggleClass('open');

							/*if(!self.allOpen) {
								self.addAllChildren($('.quota-item'));
								self.allOpen = true;
								$('.quota-btn.all').addClass('all-open').text($('.quota-btn.all').attr('data-close'));
							}*/
							//else {
								var $all = $(this);
								var openList = false;
								if($all.hasClass('all-open')) {
									openList = true;
									$all.removeClass('all-open');
								}
								else {
									openList = false;
									$all.addClass('all-open');
								}

								$('.national .quota-item').each(function() {
									var $item = $(this);
									//if(!$(this).hasClass('open')) {
									if(!openList) {
										
										$item.addClass('open').find('> ul').slideDown();
										$('.quota-btn.all').text($('.quota-btn.all').attr('data-close'));
									}
									else {
										$all.removeClass('all-open');
										$item.removeClass('open').find('> ul').slideUp();
										$('.quota-btn.all').text($('.quota-btn.all').attr('data-open'));
									}
								});
							//}
							
						}
					});
				},

				allocate: function(elt)
				{
					var self = this;
					var $this = elt;
					var $parent = $this.closest('.quota-item');
					var $assigned = $this.closest('.quota-head').find('.quota-assigned input');
					var $reserved = $this.closest('.quota-head').find('.quota-reserved input');
					var $consumed = $this.closest('.quota-head').find('.quota-consumed input');

					if($assigned.val() != '' && $assigned.val() != '0') {
						let nbAssigned = self.checkFloat(parseFloat($assigned.val()) / $parent.find('> ul > li > .quota-head .quota-assigned').length);

						if ( nbAssigned == 0 ){
							$assigned.addClass('error').closest('.quota-inputs').append('<div class="quota-error quota-error-assigned" data-assigned="assigned">' + $('.error-field-allocate').attr('data-text') + '</div>');
						}

						$parent.find('> ul > li > .quota-head .quota-assigned input').val(self.floatToStr(nbAssigned));
					}

					if($assigned.val() == '0') {
						$parent.find('ul li .quota-head .quota-assigned input').val(0);
					}
					if($assigned.val() == '') {
						$parent.find('ul li .quota-head .quota-assigned input').val('');
					}

					/*if($reserved.val() != '' && $reserved.val() != '0') {
						var nbReserved = parseFloat($reserved.val()) / $parent.find('> ul > li > .quota-head .quota-reserved').length;
						$parent.find('> ul > li > .quota-head .quota-reserved input').val(nbReserved);
					}

					if($consumed.val() != '' && $consumed.val() != '0') {
						var nbConsumed = parseFloat($consumed.val()) / $parent.find('> ul > li > .quota-head .quota-consumed').length;
						$parent.find('> ul > li > .quota-head .quota-consumed input').val(nbConsumed);
					}*/

					self.counts();
					self.totalCount();
					//});
				},
				/*
				si quota-unity <> halfday, renvoie la valeur entière
				si quota-unity == halfday, renvoie la valeur au 0.5 inférieur 
				*/
				checkFloat: function(value){
					let self = this;
					let iValue = parseInt(value);

					if ( !self.halfDay ){
						return iValue;
					}

					let reste = value - iValue;

					if ( reste < 0.5 ){
						return iValue;
					}

					return iValue + 0.5;
				},
				floatToStr: function(value){
					return parseFloat(value).toString().replace('.', ',');
				},
				strToFloat: function(value){
					return value? this.checkFloat(value.replace(',', '.')) : 0;
				},
				allocatenivs: function(){
					var self = this;
					let level = parseInt($('#quota_operation_disRegionLvl').val().replace('lvl', '')) -1;
					let quota = parseFloat($('.quota-allocatenivs-container #quota_operation_disQuantity').val().replace(',', '.'));

					if ( isNaN(quota) ){
						return;
					}

					if ( self.halfDay && quota % 0.5 != 0 ){
						$('.error-dis-quantity-float').show();
						return false;
					} else {
						$('.error-dis-quantity-float').hide();
					}
					if ( !self.halfDay && quota % 1 != 0 ){
						$('.error-dis-quantity-integer').show();
						return false;
					} else {
						$('.error-dis-quantity-integer').hide();
					}

					$('.quota-inputs.niv-' + level + ' .quota-assigned input').val(self.floatToStr(quota));

					while ( level > 0 ){
						self.calculateLevel(--level);
					}

					self.counts();
					self.totalCount();

					let totalNat = self.floatToStr(self.strToFloat($('.national .quota-head .nb-qAssigned').html()));

					$('.national > .quota-head .quota-assigned input').val(totalNat);
					$('.national > .quota-head .nb-qTotal').html(totalNat);
					$('.quota-total .total-nat').html(totalNat);
				},

				calculateLevel: function(level){
					let self = this;
					let sum = 0;

					$('.quota-item.niv-' + level).each(function(){
						sum = 0;
						$(this).find('.quota-inputs.niv-' + (level +1) + ' .quota-assigned input').each(function(index, elt){
							sum += self.strToFloat($(elt).val());
						})
						$(this).find('.quota-inputs.niv-' + level + ' .quota-assigned input').val(self.floatToStr(sum));
					});
				},

				counts: function()
				{
					var self = this;

					$('.nb-qAssigned').each(function() {
						var value = 0;
						$(this).closest('.quota-head').siblings('ul').find('> li').each(function() {
							if(!isNaN(self.strToFloat($(this).find('.quota-assigned input').val()))) {
								value += self.strToFloat($(this).find('.quota-assigned input').val());
							}
						});
						$(this).text(self.floatToStr(value));
					});
					$('.nb-qTotal').each(function() {
						var value = 0;
						if(!isNaN(self.strToFloat($(this).closest('.quota-head').find('.quota-assigned input').val()))) {
							value = self.strToFloat($(this).closest('.quota-head').find('.quota-assigned input').val());
						}
						$(this).text(self.floatToStr(value));
					});

					/*$('.quota-assigned input').change(function() {
						self.counts();
					});*/
				},

				addition: function(elt)
				{
					var self = this;

					var uncheck = false;
					var $this = elt;
					var type = $this.attr('data-quota');
					var somme = value = self.strToFloat($this.val());
					var $parent = $this.closest('ul').siblings('.quota-head').find('[data-quota="' + type + '"]');

					if(type == 'reserved') {
						return uncheck;
					}
					if($parent.length > 0) {
						$this.closest('.quota-item').siblings().each(function() {
							somme += self.strToFloat($(this).find('> .quota-head [data-quota="' + type + '"]').val());
						});

						if(somme > self.strToFloat($parent.val())) {
							//$this.addClass('error').closest('.quota-head').find('.quota-error-parent').attr('data-' + type, type);
							$this.addClass('error').closest('.quota-inputs').append('<div class="quota-error quota-error-parent" data-' + type + '="' + type + '">' + $('.error-field-parent').attr('data-text') + '</div>');
							uncheck = true;
						}
					}

					if($this.closest('ul').siblings('.quota-head').find('.nb-parent-assigned').length) {
						var sommeBis = self.strToFloat($this.val());
						$this.closest('.quota-item').siblings().each(function() {
							sommeBis += self.strToFloat($(this).find('> .quota-head [data-quota="' + type + '"]').val());
						});

						if(sommeBis > parseFloat($this.closest('ul').siblings('.quota-head').find('.nb-parent-assigned').text())) {
							//$this.addClass('error').closest('.quota-head').find('.quota-error-parent').attr('data-' + type, type);
							$this.addClass('error').closest('.quota-inputs').append('<div class="quota-error quota-error-parent" data-' + type + '="' + type + '">' + $('.error-field-parent').attr('data-text') + '</div>');
							uncheck = true;
						}
					}

					// Pour les enfants

					var $childs = $this.closest('.quota-head').siblings('ul').find('> .quota-item');
					var sommeChild = 0;
					if($childs.length > 0) {
						$this.closest('.quota-head').siblings('ul').find('> .quota-item').each(function() {
							sommeChild += self.strToFloat($(this).find('> .quota-head [data-quota="' + type + '"]').val());
						});

						if(sommeChild > value) {
							//$this.addClass('error').closest('.quota-head').find('.quota-error-child').attr('data-' + type, type);
							$this.addClass('error').closest('.quota-inputs').append('<div class="quota-error quota-error-child" data-' + type + '="' + type + '">' + $('.error-field-child').attr('data-text') + '</div>');
							uncheck = true;
						}
					}

					if($this.val() != '' && self.strToFloat($this.val()) < self.strToFloat($this.closest('.quota-inputs').find('.quota-consumed input').val())) {
						$this.addClass('error').closest('.quota-inputs').append('<div class="quota-error quota-error-consumed" data-' + type + '="' + type + '">' + $('.error-field-consumed').attr('data-text') + '</div>');
						uncheck = true;
					}

					if(!uncheck) {
						//$this.removeClass('error').closest('.quota-head').find('.quota-error').removeAttr('data-'+type);
						$this.removeClass('error').closest('.quota-inputs').find('.quota-error').remove();

						//Enfant
						//$this.closest('.quota-head').siblings('ul').find('> .quota-item > .quota-head [data-quota="' + type + '"]').removeClass('error').closest('.quota-head').find('.quota-error').removeAttr('data-'+type);
						$this.closest('.quota-head').siblings('ul').find('> .quota-item > .quota-head [data-quota="' + type + '"]').removeClass('error').closest('.quota-inputs').find('.quota-error').remove();

						//Parent
						//$this.closest('ul').siblings('.quota-head').find('.quota-error-child').removeAttr('data-'+type);
						$this.closest('ul').siblings('.quota-head').find('.quota-error-child').remove();
						if(!$this.closest('ul').siblings('.quota-head').find('.quota-error-parent').attr('data-'+type)) {
							$this.closest('ul').siblings('.quota-head').find('[data-quota="' + type + '"]').removeClass('error');
						}
					}

					return uncheck;
				},

				totalCount: function()
				{
					var self = this;

					var totalSecteur = 0,
						totalNat = 0,
						totalZone = 0,
						totalRegion = 0;

					//var totalNat = totalZone = totalRegion = totalSecteur = 0;
					if(!isNaN(self.strToFloat($('.national > .quota-head .quota-assigned input').val()))) {
						totalNat = self.strToFloat($('.national > .quota-head .quota-assigned input').val());
					}

					$('.quota-item.niv-0').each(function() {
						if(!isNaN(self.strToFloat($(this).find('> .quota-head').find('.quota-assigned input').val()))) {
							totalZone += self.strToFloat($(this).find('> .quota-head').find('.quota-assigned input').val());
						}
					});
					$('.quota-item.niv-1').each(function() {
						if(!isNaN(self.strToFloat($(this).find('> .quota-head').find('.quota-assigned input').val()))) {
							totalRegion += self.strToFloat($(this).find('> .quota-head').find('.quota-assigned input').val());
						}
					});
					$('.quota-item.niv-2').each(function() {
						if(!isNaN(self.strToFloat($(this).find('> .quota-head').find('.quota-assigned input').val()))) {
							totalSecteur += self.strToFloat($(this).find('> .quota-head').find('.quota-assigned input').val());
						}
					});

					/*if(totalNat == '' || totalNat == '0') {
						totalZone = totalRegion = totalSecteur = 0;
					}*/
					//$('.national > .quota-head .quota-assigned input').val()

					$('.quota-total .total-nat').text(self.floatToStr(totalNat));
					$('.quota-total .total-zone').text(self.floatToStr(totalZone));
					$('.quota-total .total-region').text(self.floatToStr(totalRegion));
					$('.quota-total .total-secteur').text(self.floatToStr(totalSecteur));
				},
			},

			RegionTree: {
				selectors: {},

				viewModeAjax: null,
				hasAjax: false,

				init: function()
				{
					var self = this;

					self.dropdown();
					self.viewMode();
				},

				dropdown: function()
				{
					var self = this;

					$('body').on('click', '.region-btn-slide', function() {
						$(this).closest('.region-head').siblings('ul').slideToggle().closest('.region-item').toggleClass('open');
					});

					$('body').on('click', '.region-btn-all', function() {
						var $all = $(this);
						var openList = false;
						if($all.hasClass('all-open')) {
							openList = true;
							$all.removeClass('all-open');
						}
						else {
							openList = false;
							$all.addClass('all-open');
						}

						$('.region-tree .region-item').each(function() {
							var $item = $(this);
							//if(!$(this).hasClass('open')) {
							if(!openList) {
								
								$item.addClass('open').find('> ul').slideDown();
								$('.region-btn-all').text($('.region-btn-all').attr('data-close'));
							}
							else {
								$all.removeClass('all-open');
								$item.removeClass('open').find('> ul').slideUp();
								$('.region-btn-all').text($('.region-btn-all').attr('data-open'));
							}
						});
					});
				},

				viewMode: function()
				{
					var self = this;

					//var viewModeDefault = AppGeneral.Cookie.read('viewModeRegion');


					$('body').on('click', '.switch-view-region a', function(e) {
						var $this = $(this);

						e.preventDefault();
						$(this).addClass('active').siblings().removeClass('active');
						//$('.animation-list').removeClass('loading');

						var urlMode = window.location.origin + window.location.pathname;
						if($(this).hasClass('tree-view')) {
							urlMode += '?design=tree';
						}
						else {
							if(AppGeneral.Tools.urlParam('page') != '') {
								urlMode += '?page=' + AppGeneral.Tools.urlParam('page');
							}
						}

						AppGeneral.Cookie.create('viewModeRegion', $this.attr('data-view'));

						if(self.viewModeAjax) { self.viewModeAjax.abort(); }
	                    self.viewModeAjax = $.ajax({
	                        url: urlMode,
	                        type: 'GET',
	                        beforeSend: function() {
	                        	/*if(self.hasAjax) {
	                            	setTimeout(function() { $('.content-wrapper .content').addClass('loading'); }, 300);	
	                            }
	                            else {
	                            	$('.content-wrapper .content').addClass('loading');
	                            	self.hasAjax = true;
	                            }*/
	                            $('.content-wrapper .content').addClass('loading');
	                        },
	                        success: function success(html) {
	                        	$('.content-wrapper .content').replaceWith($(html).find('.content-wrapper .content'));
	                        	$('.content-wrapper .content').removeClass('loading');
	                        }
	                    });

	                    

					});
				},
			},

			Comments: {
				selectors: {},

				init: function()
				{
					var self = this;
					if(!$('.order-comments .order-comment').length) {
						$('.see-comments').hide();
					}

					if($('.order-comment-container').length && window.location.hash == "#comment") {
						$('.order-comment-add, .order-comments').show();
					}

					$('.see-comments').click(function(e) {
						e.preventDefault();
						$('.order-comments').slideDown();
					});

					$('.do-comment').click(function(e) {
						e.preventDefault();
						$('.order-comment-add').slideDown();
					});

					$(document).on('submit', '.order-comment-add form', function(e) {
						e.preventDefault();

						var url = window.location.origin + window.location.pathname;
						$.ajax({
							type: "POST",
							url: $(this).attr('action'),
							data: $(this).serialize(),
							beforeSend: function() {
								$('.order-comment-container').addClass('loading');
							},
							success: function(data) {
								$('.order-comment-container').replaceWith($(data).find('.order-comment-container'));
								$('.order-comments, .order-comment-add').show();
								$('html, body').animate({ scrollTop : $('.order-comment:last-child').offset().top });
							}
						});

					});
				},
			},

			CheckForm: {
				selectors: {},

				init: function()
				{
					var self = this;

					$('.form-custom form input[required], #public_holiday_fakeDate').addClass('required').removeAttr('required');

					$('.form-custom form').submit(function() {
						let customError = '';
						if(!$(this).hasClass('form-user-edit')) {
							var valid = true;
							$(this).find('input.required').removeClass('error-field');
							$(this).find('.dates-tabs li').removeClass('error');
							$('.error-field-alert').remove();

							$(this).find('input.required').each(function() {
								if($(this).val() == '') {
									$(this).addClass('error-field');
									$('.dates-tabs [data-type="' + $(this).closest('.dates-item').attr('data-type') + '"]').parent('li').addClass('error');
									valid = false;
									customError += "<li>" + $(this).parent().find('label').html() + '</li>';
								}
							});

							if($(".refrigeratorbin-agency-group").length) {
								if(!$(".refrigeratorbin-agency-group input:checked").length) {
									$(".refrigeratorbin-agency-group").addClass("error-field");
									valid = false;
								}
							}
							
							if($('#public_holiday_fakeDate').length && $('#public_holiday_fakeDate').val() == '') {
								$(this).addClass('error-field');
								valid = false;
							}

							if($('.dates-interval').length) {
								$('.dates-interval').each(function() {
									var $this = $(this);
									if($this.find('.start').val() != '' && $this.find('end').val() != '') {
										var dateStart = new Date($this.find('.start').val()).getTime();
										var dateEnd = new Date($this.find('.end').val()).getTime();
										if(dateStart > dateEnd) {
											$this.after('<div class="error-field-alert min">' + $('.error-field-interval').attr('data-text') + '</div>');
											$this.find('.start, .end').addClass('error-field');
											$('.dates-tabs [data-type="' + $(this).closest('.dates-item').attr('data-type') + '"]').parent('li').addClass('error');
											valid = false;
										}
									}
								});
							}

							if(!valid) {
								if ( customError && $('.form-custom.form-operation').length ){
									customError = '<div>' + $('.error-field-missing').attr('data-text') + '<ul>' + customError + '</ul></div>';
								}
								
								$(this).append('<div class="error-field-alert">' + $('.error-field-text').attr('data-text') + customError + '</div>');
								
								if($('.dates-tabs li.error').length) {
									$('html, body').animate({ 'scrollTop' : $('.dates-tabs li.error').offset().top - 20 });
								}
								else if($('.error-field').length) {
									$('html, body').animate({ 'scrollTop' : $('.error-field').offset().top - 20 });
								}
								return false;
							}
						}
					});

					$('input.required').focusin(function() {
						$(this).removeClass('error-field');
						$('.dates-tabs [data-type="' + $(this).closest('.dates-item').attr('data-type') + '"]').parent('li').removeClass('error');
					});
					$('.dates-interval input').focusin(function() {
						$('.dates-tabs [data-type="' + $(this).closest('.dates-item').attr('data-type') + '"]').parent('li').removeClass('error');
						$(this).removeClass('error-field');
						$('.dates-interval').siblings('.error-field-alert').remove();
					});

					$(".refrigeratorbin-agency-group input").change(function() {
						$(this).closest(".refrigeratorbin-agency-group").removeClass("error-field");
					});

					self.dates();
				},

				dates: function()
				{
					var self = this;

					var dateYear = $('#public_holiday_dateTime_date_year').val();
					var dateMonth = $('#public_holiday_dateTime_date_month').val();
					var dateDay = $('#public_holiday_dateTime_date_day').val();
					if(dateYear == 2015 && dateMonth == 1 && dateDay == 1) {
						var d = new Date();
						dateYear = d.getFullYear();
						dateMonth = d.getMonth();
						dateDay = d.getDate();
					}
					if($('#public_holiday_date').val() != '') {
						//$('#public_holiday_fakeDate').val(new Date($('#public_holiday_date').val()*1000).toLocaleDateString("fr"));
						$('#public_holiday_fakeDate').val(new Date(dateYear, dateMonth -1, dateDay, 0, 0, 0).toLocaleDateString("fr"));
					}

					$('#public_holiday_fakeDate').datepicker({
						language: 'fr',
						format: 'dd/mm/yyyy',
						//dateFormat: "mm/dd/yyyy",
						//altFormat: "yy-mm-dd",	
					}).on('change', function() {
						$('#public_holiday_dateTime_date_day').val($('#public_holiday_fakeDate').datepicker('getDate').getDate());
						$('#public_holiday_dateTime_date_month').val($('#public_holiday_fakeDate').datepicker('getDate').getMonth() + 1);
						$('#public_holiday_dateTime_date_year').val($('#public_holiday_fakeDate').datepicker('getDate').getFullYear());
						$('#public_holiday_dateTime_time_hour').val("0");
						$('#public_holiday_dateTime_time_minute').val("0");

						var selectedDate = $('#public_holiday_fakeDate').datepicker('getDate').getTime();
						$('#public_holiday_date').val(selectedDate/1000 + (Math.abs(new Date(selectedDate).getTimezoneOffset()) * 60));

					});

					$('.form-custom [type="date"], .row-filters [type="date"]').each(function() {
						if($(this).prop('type') != 'date' ) {
							$(this).datepicker({
								language: 'fr',
								dateFormat: "dd/mm/yyyy",
								'format': 'dd/mm/yyyy',
							});
						}
					});

					$('.form-custom [type="datetime-local"]').each(function() {
						if($(this).prop('type') != 'datetime-local' ) { 
							$(this).daterangepicker({
								singleDatePicker: true,
								timePicker: true,
								locale: {
							      format: 'DD/MM/YYYY hh:mm A'
							    },
							});
						}
					});
				},
			},

			Dashboard: {
				selectors: {},

				init: function()
				{
					var self = this;

					self.choice();
					self.news();
				},

				choice: function()
				{
					var self = this;

					$('.dashboard-choice input').on('click', function() {
						$(this).closest('form').submit();
					});
				},

				news: function()
				{
					var self = this;

					$('.section-main-news .btn-more').on('click', function() {
						$(this).closest('.section-main-news').toggleClass('open');
					});
				},
			},
		},

		Cookie: {
			selectors: {},

			init: function()
			{
				var self = this;
			},

			create: function(nom, valeur, jours) 
			{
				var self = this;

				// Le nombre de jours est spécifié
				if (jours) {
					var date = new Date();
				    // Converti le nombre de jour en millisecondes
					date.setTime(date.getTime()+(jours*24*60*60*1000));
					var expire = "; expire="+date.toGMTString();
				}
				        // Aucune valeur de jours spécifiée
				else {
					var expire = "";
				}
				document.cookie = nom+"="+valeur+expire+"; path=/";
			},

			read: function(nom)
			{
				var self = this;

				if(document.cookie.length == 0) {
				    return null;
				}

			    var regSepCookie = new RegExp('(; )', 'g');
			    var cookies = document.cookie.split(regSepCookie);

			    for(var i = 0; i < cookies.length; i++){
			       var regInfo = new RegExp('=', 'g');
			       var infos = cookies[i].split(regInfo);
			       if(infos[0] == nom){
			         return unescape(infos[1]);
			       }
			    }
			    return null;
			},

			delete: function(nom, valeur)
			{
				var self = this;

				creerCookie(nom, valeur, -1);
			},
		}

	};

	$(document).ready(function(){
		// Permet de bind un event en priorité
		$.fn.bindFirst = function(name, fn) {
			var elem, handlers, i, _len;
			this.bind(name, fn);
			for (i = 0, _len = this.length; i < _len; i++) {
				elem = this[i];
				handlers = jQuery._data(elem).events[name.split('.')[0]];
				handlers.unshift(handlers.pop());
			}
		};

		AppGeneral.init();

	});
}(jQuery));
